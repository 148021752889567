import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Image from "../components/image"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Hero from "../components/hero"

export default () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Meta title="車両詳細" />
      <Hero title="車両詳細" />
      <div className="max-w-3xl mx-auto px-2">
        <h2 className="text-center text-2xl font-bold leading-relaxed text-gray-800 mb-4">
          ポルシェ 928GT
        </h2>
        <Slider
          {...settings}
          className="mb-10 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <div>
            <Image filename="928gt.jpeg" alt="" />
          </div>
          <div>
            <Image filename="928gt2.jpeg" alt="" />
          </div>
          <div>
            <Image filename="928gt3.jpeg" alt="" />
          </div>
          <div>
            <Image filename="928gt4.jpeg" alt="" />
          </div>
          <div>
            <Image filename="928gt5.jpeg" alt="" />
          </div>
          <div>
            <Image filename="928gt6.jpeg" alt="" />
          </div>
        </Slider>
        <div>
          <h3 className="text-center text-red-700 font-bold text-xl">
            SOLD OUT
          </h3>
        </div>
        <div className="mb-8 max-w-md mx-auto" style={{ width: "fit-content" }}>
          <dl className="text-gray-800 flex">
            <dt className="font-bold w-20">年式</dt>
            <dd className="mb-1 w-full">1994年</dd>
          </dl>
          <dl className="text-gray-800 flex">
            <dt className="font-bold w-20">H/D</dt>
            <dd className="mb-1 w-full">左ハンドル</dd>
          </dl>
        </div>
        <Link
          to="/contact/"
          className="block text-white bg-red-600 font-bold rounded px-4 py-3 w-64 text-center mx-auto mb-16"
        >
          お問い合わせ
        </Link>
      </div>
    </Layout>
  )
}
